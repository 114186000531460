<template>
  <app-icon-btn
    :disabled="disabled"
    :loading="loading"
    class="ml-2"
    color="grey"
    icon="icon-download"
    size="17px"
    @click="download"
  />
</template>

<script>
import OpenFileMixin from '@/mixins/OpenFileMixin';
import formatDate from '@/helper/filter/formatDate';

export default {
  name: 'CsvDownload',

  mixins: [OpenFileMixin],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loadCsv: {
      type: Function,
      required: true
    }
  },

  methods: {
    async download() {
      this.loading = true;

      const { file, error } = await this.loadCsv();

      if (error) {
        this.loading = false;
        return;
      }

      this.triggerDownload(
        file,
        this.$t('requests.csvExportFilename', { date: formatDate(new Date()) }),
        true
      );

      this.loading = false;
    }
  }
};
</script>
