<template>
  <v-container fluid pa-0>
    <requests-filter />

    <requests-table :table-data="requests" :count="requestsCount">
      <template #csv-download>
        <app-tooltip text="requests.csvExportInfo">
          <csv-download :disabled="requests.length === 0" :load-csv="loadCSV" />
        </app-tooltip>
      </template>
    </requests-table>

    <answer-request-dialog
      v-if="detailedRequest"
      v-model="answerRequest"
      :details="detailedRequest"
      @success="loadList"
    />
  </v-container>
</template>

<script>
import AnswerRequestDialog from '@/modules/Admin/components/Requests/Actions/AnswerSepaRequestDialog';
import CreditorListMixin from '@/modules/Admin/mixins/CreditorListMixin';
import CsvDownload from '@/modules/Admin/components/Requests/CsvDownload';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import RequestPermissionMixin from '@/modules/Admin/mixins/RequestPermissionMixin';
import RequestStatus from '@/modules/Admin/statics/requestStatus';
import RequestsFilter from '@/modules/Admin/components/Requests/RequestsFilter';
import RequestsTable from '@/modules/Admin/components/Requests/RequestsTable';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions, mapGetters, mapState } from 'vuex';
import { QueryParam } from '@/statics/queryParam';

export default {
  name: 'Requests',

  mixins: [CreditorListMixin, ListFilterMixin, RequestPermissionMixin],

  components: {
    AnswerRequestDialog,
    CsvDownload,
    RequestsFilter,
    RequestsTable
  },

  data: () => ({
    queryValues: [
      QueryParam.PAGE,
      QueryParam.ITEMS_PER_PAGE,
      QueryParam.SORT_BY,
      QueryParam.SORT_DESC,
      'status'
    ],
    defaultSorting: {
      sortBy: ['created'],
      sortDesc: [true]
    },
    defaultFilter: {
      status: [RequestStatus.OPEN]
    }
  }),

  computed: {
    ...mapState(NAMESPACE, ['requests', 'requestsCount']),
    ...mapGetters(NAMESPACE, { getRequestById: 'GET_REQUEST_BY_ID' }),
    detailedRequest() {
      return this.getRequestById(this.getQueryParam(this.queryParam.ANSWER_REQUEST));
    },
    answerRequest: {
      get() {
        return (
          this.getQueryParam(this.queryParam.ANSWER_REQUEST) &&
          this.userHasAnswerPermission(this.detailedRequest)
        );
      },
      set(value) {
        this.setQueryParam(this.queryParam.ANSWER_REQUEST, value);
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchRequests', 'fetchRequestsCSV']),

    loadList(query = this.defaultQuery) {
      this.fetchRequests({
        page: query[QueryParam.PAGE],
        rowsPerPage: query[QueryParam.ITEMS_PER_PAGE],
        filters: this.buildFilters(),
        sorting: this.buildSorting(query)
      });
    },

    loadCSV() {
      return this.fetchRequestsCSV({
        page: 1,
        rowsPerPage: this.requestsCount ?? 0,
        filters: this.buildFilters(),
        sorting: this.buildSorting(this.$route.query)
      });
    },

    buildFilters() {
      let filters = {};

      if (this.statusSelection.length > 0) {
        filters.status = this.statusSelection;
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    }
  },

  watch: {
    '$route.query': {
      deep: true,
      handler(query) {
        this.updateTableQueries(query, this.queryValues, this.loadList);
      }
    }
  },

  created() {
    this.loadList();
  }
};
</script>
